// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "where-u-dashboard.firebaseapp.com",
  projectId: "where-u-dashboard",
  storageBucket: "where-u-dashboard.appspot.com",
  messagingSenderId: "868631108757",
  appId: "1:868631108757:web:a7570c01aab7405731cf9c"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();

export {
  auth,
  db,
};