import React from 'react'
import axios from "axios"

const accessToken = process.env.REACT_APP_FACEBOOK_ACCESS_TOKEN;
const accountId = process.env.REACT_APP_FACEBOOK_ACCOUNTID;
const apiVer = "v12.0";

let endDateEpoch = new Date();
let startDateEpoch = new Date();
startDateEpoch.setDate(-30);
let endTime = endDateEpoch.getTime();
let startTime = startDateEpoch.getTime();
let campaignAdSpend = [];
let lastWeek = new Date();
lastWeek.setDate(-5);
let lastWeekEpoch = lastWeek.getTime();

// TODO:
// Create batch request for Campaign Insights to send single request

class FB7DaySummary extends React.Component {
  state = {
    adSpend: 0,
  }

  componentDidMount(){
    axios({
      method: "get",
      url: "https://graph.facebook.com/" + apiVer + "/" + accountId + "/insights?limit=60&date_preset=last_7d&access_token=" + accessToken
    })
      .then(async res => {
        const adSpend = res.data.data[0].spend;
        this.setState({ adSpend });
      }
      )
  }
  
  render() {
    return(
    <p>${this.state.adSpend}</p>
    )
  }
}

class FBCampaigns extends React.Component {
  state = {
    campaigns: [],
    campaignAdSpend: [],
  }

fetchCampaignData(campaignId){
  axios({
    method: "get",
    url: "https://graph.facebook.com/" + apiVer + "/" + campaignId + "/insights?limit=60&access_token=" + accessToken + "&fields=campaign_name,impressions,inline_link_clicks,spend&start_time=" + startTime + "&end_time=" + endTime
  })
    .then(res => {
      let campaignData = res.data.data;
      campaignData["campaignId"] = campaignId;
      campaignAdSpend[campaignId] = campaignData;
      this.setState({ campaignAdSpend });
    })
}

  componentDidMount() {
    axios({
      method: "get",
      url: "https://graph.facebook.com/" + apiVer + "/" + accountId + "/campaigns?limit=60&access_token=" + accessToken
    })
      .then(async res => {
        const campaigns = res.data.data;
        for (let i = 0; i < campaigns.length; i++) {
          this.fetchCampaignData(campaigns[i].id)
        }
        this.setState({ campaigns });
      }
      )
  }

  renderTableData(adSpend) {
      return (
        <>
          <td>{adSpend["campaignId"]}</td>
          <td>{adSpend[0]["campaign_name"]}</td>
          <td>{adSpend[0]["impressions"]}</td>
          <td>{adSpend[0]["inline_link_clicks"]}</td>
          <td>{adSpend[0]["spend"]}</td>
          <td>{adSpend[0]["date_start"]}</td>
          <td>{adSpend[0]["date_stop"]}</td>
        </>
        )
      }

      render() {

        let tableRow;
        if (this.state.campaignAdSpend === undefined) { tableRow = <tr><td>Data is Loading. . .</td></tr> }
        else if (this.state.campaignAdSpend[23848827401400130] === undefined) { tableRow = <tr><td>Data is Loading. . .</td></tr> }
        else {
          tableRow = <tr>{this.renderTableData(this.state.campaignAdSpend[23848827401400130])}</tr>
        }

        return (
          <table>
            <tbody>
              <tr>
                <th>Campaign ID</th>
                <th>Campaign Name</th>
                <th>Impressions</th>
                <th>Link Clinks</th>
                <th>Spend</th>
                <th>Start</th>
                <th>End</th>
              </tr>
              {tableRow}
            </tbody>
          </table>
        )
      }
    }
  
export { FBCampaigns, FB7DaySummary }