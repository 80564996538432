import React from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
// import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
// import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { TogglHours } from "components/api/toggl.js";
import { TwilioCallLogs } from "variables/twilio.js";
import { AppointmentsByBooking, TrafficDiscoverySessionSubmissions, ClientOnboardingSubmissions, ReferralTrafficSubmissions, TrafficVSLSubmissions, WebPresenceSubmissions, WebsiteVSLSubmissions } from "variables/gohighlevel.js";
import { FBCampaigns, FB7DaySummary } from "variables/facebook.js"
import { TogglChart } from "components/api/TogglChart.js"
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { auth } from 'firebase.js';
import { StripeSummary, StripeMRR } from "variables/stripe.js"
// import { SalesCard } from "components/sales/sales.js"

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const loginState = auth.currentUser;
  if (loginState != undefined) {
  return (
    <div>
      {console.log(loginState)}
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Monthly Recurring Revenue</p>
              <h3 className={classes.cardTitle}>< StripeMRR /></h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Last 31 Days
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>GHL Traffic Discovery Form Submissions</p>
              <h3 className={classes.cardTitle}>< TrafficDiscoverySessionSubmissions /></h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                Last 30 Days
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>GHL Traffic VSL Submissions</p>
              <h3 className={classes.cardTitle}>< TrafficVSLSubmissions /></h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                Last 30 Days
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <p className={classes.cardCategory}>Facebook Spend (7 Days)</p>
              <h3 className={classes.cardTitle}>< FB7DaySummary /></h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>GHL Stats</h4>
              <p className={classes.cardCategoryWhite}>
                Form Submission in the Last 30 Days
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="warning"
                tableHead={["Form Name", "Submission Count"]}
                tableData={[
                  ["GHL Traffic Discovery Form Submissions", < TrafficDiscoverySessionSubmissions />],
                  ["GHL Client Onboarding Submissions", < ClientOnboardingSubmissions />],
                  ["GHL Referral Traffic Submissions", < ReferralTrafficSubmissions />],
                  ["GHL Traffic VSL Submissions", < TrafficVSLSubmissions />],
                  ["GHL Web Presence Workshop Submissions", < WebPresenceSubmissions />],
                  ["GHL Website VSL Submissions", < WebsiteVSLSubmissions />],
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>GHL Appointments</h4>
              <p className={classes.cardCategoryWhite}>
                Appointments in the Last 30 Days
              </p>
            </CardHeader>
            <CardBody>
              < AppointmentsByBooking />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Running Campaigns</h4>
              <p className={classes.cardCategoryWhite}>
                Last 30 Days
              </p>
            </CardHeader>
            <CardBody>
              < FBCampaigns />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>content_copy</Icon>
              </CardIcon>
              <h3 className={classes.cardTitle}>Toggl</h3>
              <p className={classes.cardCategory}></p>
                <TogglHours />
            </CardHeader>
            <CardFooter stats>
            <div className={classes.stats}>
              <AccessTime /> From 00/00/0000 - 00/00/0000
            </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>content_copy</Icon>
              </CardIcon>
              <h3 className={classes.cardTitle}>Toggl</h3>
              <p className={classes.cardCategory}></p>
              <TogglChart />
            </CardHeader>
            <CardFooter stats>
            <div className={classes.stats}>
              <AccessTime /> From 00/00/0000 - 00/00/0000
            </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <h3 className={classes.cardTitle}>Twilio</h3>
            </CardHeader>
            <CardBody>
              <TwilioCallLogs />
            </CardBody>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <h3 className={classes.cardTitle}>Stripe Invoice Status</h3>
            </CardHeader>
            <CardBody>
              <StripeSummary />
            </CardBody>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      
    </div>
  );} else {
    return (
      <div> 403 - Unauthorised Access</div>
    )
  }
}