import React from "react";
import axios from "axios";
import ShopIcon from "@material-ui/icons/Shop";
import CustomTabs from "components/CustomTabs/CustomTabs.js";

var togglUid = process.env.REACT_APP_TOGGL_UID;
var authPW = "api_token";

class TogglHours extends React.Component {
  state = {
    weeklyData: [],
    clientHours: [],
    projectHours: [],
}

componentDidMount() {
  let clientNames = [];
  let clientHours = [];
  let projectHours = [];
  let clientName = "";
  let projectName = "";

  axios( {
    auth: {
        username: togglUid,
        password: authPW
    },
    method: "get",
    url: "https://api.track.toggl.com/reports/api/v2/weekly?user_agent=chris@whereu.com.au&workspace_id=3692453"
    })
    .then(res => {
        const weeklyData = res.data.data;
        this.setState({ weeklyData });
        for (var i = 0; i < weeklyData.length; i++) { // Retrieve Client List & Init Client Hours
          clientName = weeklyData[i].title.client;
          clientNames[clientName] = clientName;
          clientHours[clientName] = [];
          clientHours[clientName]["Total"] = 0;
        }
        for (var i = 0; i < weeklyData.length; i++) { // Tally Project Hours for Week
            for (var e = 0; e < weeklyData[i].totals.length; e++){
              clientName = weeklyData[i].title.client;
              projectName = weeklyData[i].title.project;
              if (weeklyData[i].totals[e] == null){ // replace null values for math ops
                weeklyData[i].totals[e] = 0;
              }
              if (projectHours[projectName] == null){ // replace null values for math ops
                projectHours[projectName] = 0;
              }
              projectHours[projectName] = projectHours[projectName] + weeklyData[i].totals[7];
              clientHours[clientName][projectName] = weeklyData[i].totals[7];
              clientHours[clientName]["Total"] = clientHours[clientName]["Total"] + weeklyData[i].totals[7];
          }
        }
        this.setState({ clientHours });
        this.setState({ projectHours });

    })
}

msToHms(d) {
  d = Number(d);
  var h = Math.floor(d / 3600000);
  var m = Math.floor(d % 3600000 / 60000);
  var s = Math.floor(d % 3600000 % 60000 / 1000);

  return (h + ":" + m + ":" + s); 
}

renderProjectTableRow(){
  return Object.keys(this.state.projectHours).map(projects =>
    {    
      return (
        <tr key={projects}>
          <td style={{textAlign: "left"}}>{projects}</td>
          <td style={{textAlign: "right"}}>{this.msToHms(this.state.projectHours[projects])}</td>
        </tr>
      )
    }
  )
}

renderTableRow(hoursSpent){
  return Object.keys(hoursSpent).map(names =>
    {    
      return (
        <tr key={names}>
          <td style={{textAlign: "left"}}>{names}</td>
          <td style={{textAlign: "right"}}>{this.msToHms(this.state.clientHours[names]["Total"])}</td>
        </tr>
      )
    }
  )
}

render() {
  return (
    <>
      <CustomTabs
            title="Hours Spent:"
            headerColor="primary"
            tabs={[
              {
                tabName: "By Client",
                tabIcon: ShopIcon,
                tabContent: (
                  <table id='Toggl Client Hours'>
                    <tbody>
                      {this.renderTableRow(this.state.clientHours)}
                    </tbody>
                  </table>
                )
              },
              {
                tabName: "By Task",
                tabIcon: ShopIcon,
                tabContent: (
                  <table id='Toggl Project Hours'>
                    <tbody>
                      {this.renderProjectTableRow()}
                    </tbody>
                  </table>
                )
              },
          ]}
          />
        
  </>
  )
}
}

export { TogglHours }