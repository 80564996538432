import React from 'react'
import axios from "axios"

const twilioSID = process.env.REACT_APP_TWILIOSID;
const authPW = process.env.REACT_APP_TWILIOPASSWORD;
let date = new Date();
// var timeCurrent = date.toISOString()
date.setDate(date.getDate() - 30);
let startTime = date.toISOString().substring(0, 10)
let safeStartTimeString = startTime + "T00%3A00%3A00Z"
let mobileNumbers = []
mobileNumbers[0] = ["Dan", "61488847068"]
mobileNumbers[1] = ["Caitlin", "61488822310"]
mobileNumbers[2] = ["GHL", "61488852950"]

class TwilioCallLogs extends React.Component {
  state = {
    callLogs: [],
    callTotals: [],
    mobileNumbers: [],
}

componentDidMount() {
    axios({
      auth: {
          username: twilioSID,
          password: authPW
      },
      method: "get",
      url: "https://api.twilio.com/2010-04-01/Accounts/"+ twilioSID +"/Calls.json?StartTime=" + safeStartTimeString + "&Status=completed&PageSize=100"
    })
  .then(res  => {
    let callLogs = []
    let callTotals = res.data.end + 1
    for (var i = 0; i < res.data.calls.length; i++){
      callLogs[i] = [];
      callLogs[i]["UUID"] = res.data.calls[i].sid
      callLogs[i]["To"] = res.data.calls[i].to;
      callLogs[i]["From"] = res.data.calls[i].from;
      callLogs[i]["Date"] = res.data.calls[i].date_created;
      callLogs[i]["Direction"] = res.data.calls[i].direction;
      callLogs[i]["Duration"] = res.data.calls[i].duration;
    }
    if (res.data.calls.length == 0){
      for (var x = 0; x < mobileNumbers.length; x++){
        callLogs[x] = [];
        callLogs[x]["UUID"] = 0;
        callLogs[x]["To"] = "N/A";
        callLogs[x]["From"] = "N/A";
        callLogs[x]["Date"] = "N/A";
        callLogs[x]["Direction"] = "N/A";
        callLogs[x]["Duration"] = "N/A";
      }
    }
    this.setState({ callLogs });
    this.setState({ callTotals });
    this.setState({ mobileNumbers })
  })
}

renderTableData(callLogs){
  return callLogs.map((value, index) => {
    return(
      <tr>
      <td>{callLogs[index]["To"]}</td>
      <td>{callLogs[index]["From"]}</td>
      <td>{callLogs[index]["Date"]}</td>
      <td>{callLogs[index]["Direction"]}</td>
      <td>{callLogs[index]["Duration"]}s</td>
    </tr>)
  }
    )
}

      render() {
        let tableRow
        if (this.state.callLogs === undefined || this.state.callLogs[0] === undefined ||this.state.callLogs[1] === undefined || this.state.callLogs[2] === undefined){tableRow = <tr><td>Data is Loading. . .</td></tr>}
        else if (this.state.callLogs[0] === undefined){tableRow = <tr><td>Data is Loading. . .</td></tr>}
        else if (this.state.callTotals > this.state.callLogs.length){tableRow = <tr><td>Data is Loading. . .</td></tr>}
        else if (this.state.callLogs[0]["UUID"] === 0 && this.state.callLogs[1]["UUID"] === 0 && this.state.callLogs[2]["UUID"] === 0){tableRow = <tr><td>No Data to Display</td></tr>}
        else {
          tableRow = this.renderTableData(this.state.callLogs)
        }
        return (
          <table>
            <tbody>
              <tr>
                <th>To</th>
                <th>From</th>
                <th>Date</th>
                <th>Direction</th>
                <th>Duration</th>
              </tr>
              {tableRow}
          </tbody>
        </table>
        )
      }
    }

export { TwilioCallLogs }