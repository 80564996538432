import React from 'react'
import axios from "axios";

// Get Updates
const accessToken = process.env.REACT_APP_HIGHLEVEL_ACCESS_TOKEN;
const apiVer = "v1";
let formId = [];
formId[0] = ["Traffic Discovery Session", "geCFRFEoW2uO18qIkgQb"];
formId[1] = ["Client Onboarding", "6KY1lltzEPxy87QbhXSJ"];
formId[2] = ["Referral Traffic Discovery Session", "lm65DnD9CSXcx2QJJ5RR"];
formId[3] = ["Traffic VSL Opt In", "kst3za22rarY8K2ndDr5"];
formId[4] = ["Web Presence Workshop","PZjIbuUnFC3WU7XzpZ0w"];
formId[5] = ["Website VSL Opt In", "FsN7yJ7vMVTQXbs1Hpyw"];
let teamId = "Y02A6WgrKfPuWLbkvoOx" // Traffic Discovery Session

let endDateEpoch = new Date();
let startDateEpoch = new Date();
startDateEpoch.setDate(-30);

let endTime = endDateEpoch.getTime();
let startTime = startDateEpoch.getTime();

var date = new Date();
var formatDay = date.getDate();
if (formatDay < 10){ //  ISO Standard
  formatDay = "0" + formatDay;
}

var formatEndMonth = date.getMonth()+1; // Jan 0
if (formatEndMonth < 10){  //  ISO Standard
  formatEndMonth = "0"+formatEndMonth;
}

var formatStartYear = date.getFullYear()

var formatStartMonth = date.getMonth();
if (formatStartMonth == 0){  // Fix Dec/Jan
  formatStartMonth = "12";
  formatStartYear = formatStartYear-1;
}  else if (formatStartMonth == 0){ // Fix Jan/Feb
  formatStartMonth = "01";
} else if (formatStartMonth < 10){ //  ISO Standard
  formatStartMonth = "0" + formatStartMonth;
}




var endDate = date.getFullYear() + "-" + formatEndMonth + "-" + formatDay;
var startDate = formatStartYear + "-" + formatStartMonth + "-" + formatDay;


class TrafficDiscoverySessionSubmissions extends React.Component {
  state = {
    GHLFormSubmissions: []
}
componentDidMount() {
  axios( {
    headers: {
      Authorization: 'Bearer ' + accessToken
    },
    method: "get",
    url: "https://rest.gohighlevel.com/"+apiVer+"/forms/submissions?limit=0&formId="+ formId[0][1] + "&startAt=" + startDate + "&endAt=" + endDate,
  })
      .then(res => {
        const GHLFormSubmissions = res.data.meta.total;
        this.setState({ GHLFormSubmissions });
    })
    .catch((error) => {
      console.log(error); //Logs a string: Error: Request failed with status code 404
    });
    }

    render() {
      return (
        <> 
          {this.state.GHLFormSubmissions}
        </>
      )
    }
  }

  class ClientOnboardingSubmissions extends React.Component {
    state = {
      GHLFormSubmissions: []
  }
  componentDidMount() {
    axios( {
      headers: {
        Authorization: 'Bearer ' + accessToken
      },
      method: "get",
      url: "https://rest.gohighlevel.com/"+apiVer+"/forms/submissions?limit=0&formId="+ formId[1][1] + "&startAt=" + startDate + "&endAt=" + endDate,
    })
        .then(res => {
          const GHLFormSubmissions = res.data.meta.total;
          this.setState({ GHLFormSubmissions });
      })
      .catch((error) => {
        console.log(error); //Logs a string: Error: Request failed with status code 404
      });
      }
  
      render() {
        return (
          <> 
            {this.state.GHLFormSubmissions}
          </>
        )
      }
    }

    class ReferralTrafficSubmissions extends React.Component {
      state = {
        GHLFormSubmissions: []
    }
    componentDidMount() {
      axios( {
        headers: {
          Authorization: 'Bearer ' + accessToken
        },
        method: "get",
        url: "https://rest.gohighlevel.com/"+apiVer+"/forms/submissions?limit=0&formId="+ formId[2][1] + "&startAt=" + startDate + "&endAt=" + endDate,
      })
          .then(res => {
            const GHLFormSubmissions = res.data.meta.total;
            this.setState({ GHLFormSubmissions });
        })
        }
    
        render() {
          return (
            <> 
              {this.state.GHLFormSubmissions}
            </>
          )
        }
      }

      class TrafficVSLSubmissions extends React.Component {
        state = {
          GHLFormSubmissions: []
      }
      componentDidMount() {
        axios( {
          headers: {
            Authorization: 'Bearer ' + accessToken
          },
          method: "get",
          url: "https://rest.gohighlevel.com/"+apiVer+"/forms/submissions?limit=0&formId="+ formId[3][1] + "&startAt=" + startDate + "&endAt=" + endDate,
        })
            .then(res => {
              const GHLFormSubmissions = res.data.meta.total;
              this.setState({ GHLFormSubmissions });
          })
          .catch((error) => {
            console.log(error); //Logs a string: Error: Request failed with status code 404
          });
          }
      
          render() {
            return (
              <> 
                {this.state.GHLFormSubmissions}
              </>
            )
          }
        }

        class WebPresenceSubmissions extends React.Component {
          state = {
            GHLFormSubmissions: []
        }
        componentDidMount() {
          axios( {
            headers: {
              Authorization: 'Bearer ' + accessToken
            },
            method: "get",
            url: "https://rest.gohighlevel.com/"+apiVer+"/forms/submissions?limit=0&formId="+ formId[4][1] + "&startAt=" + startDate + "&endAt=" + endDate,
          })
              .then(res => {
                const GHLFormSubmissions = res.data.meta.total;
                this.setState({ GHLFormSubmissions });
            })
            .catch((error) => {
              console.log(error); //Logs a string: Error: Request failed with status code 404
            });
            }
        
            render() {
              return (
                <> 
                  {this.state.GHLFormSubmissions}
                </>
              )
            }
          }

          class WebsiteVSLSubmissions extends React.Component {
            state = {
              GHLFormSubmissions: []
          }
          componentDidMount() {
            axios( {
              headers: {
                Authorization: 'Bearer ' + accessToken
              },
              method: "get",
              url: "https://rest.gohighlevel.com/"+apiVer+"/forms/submissions?limit=0&formId="+ formId[5][1] + "&startAt=" + startDate + "&endAt=" + endDate,
            })
                .then(res => {
                  const GHLFormSubmissions = res.data.meta.total;
                  this.setState({ GHLFormSubmissions });
              })
              .catch((error) => {
                console.log(error); //Logs a string: Error: Request failed with status code 404
              });
              }
          
              render() {
                return (
                  <> 
                    {this.state.GHLFormSubmissions}
                  </>
                )
              }
            }

            class AppointmentsByBooking extends React.Component {
              state = {
                GHLAppointments: []
            }
            componentDidMount() {
              axios( {
                headers: {
                  Authorization: 'Bearer ' + accessToken
                },
                method: "get",
                url: "https://rest.gohighlevel.com/"+apiVer+"/appointments/?startDate=" + startTime + "&endDate=" + endTime + "&teamId=" + teamId + "&includeAll=true",
              })
                  .then(res => {
                    const GHLAppointments = res.data.appointments;
                    for (let i = 0; i < GHLAppointments.length; i++){ // make calendarId Human readable
                        if (GHLAppointments[i]["calendarId"] == "EdmTmmBmAI6X2h39QEcV"){
                          GHLAppointments[i]["calenderName"] = "Traffic Discovery Session"
                        } else {
                          GHLAppointments[i]["calenderName"] = GHLAppointments[i]["calendarId"]
                        }
                    }
                    this.setState({ GHLAppointments });
                })
                }

                renderTableData(appointments){
                  return appointments.map((value, index) => {
                    return(
                      <tr>
                      <td>{appointments[index]["id"]}</td>
                      <td>{appointments[index]["appointmentStatus"]}</td>
                      <td>{appointments[index]["calenderName"]}</td>
                      <td>{appointments[index]["status"]}</td>
                      <td>{appointments[index]["startTime"]}</td>
                      <td>{appointments[index]["title"]}</td>
                    </tr>)
                  }
                    )
                }
                
                render() {
                  let tableRow;
                  if (this.state.GHLAppointments === undefined){tableRow = <tr><td>Data is Loading. . .</td></tr>}
        else if (this.state.GHLAppointments[0] === undefined){tableRow = <tr><td>Data is Loading. . .</td></tr>}
        else {
          tableRow = this.renderTableData(this.state.GHLAppointments)
        }
        return (
          <table>
            <tbody>
              <tr>
                <th>Booking ID</th>
                <th>Calendar</th>
                <th>Appointment Status</th>
                <th>Status</th>
                <th>Date</th>
                <th>Appointment Name</th>
              </tr>
              {tableRow}
          </tbody>
        </table>
                  )
                }
              }

export { AppointmentsByBooking, TrafficDiscoverySessionSubmissions, ClientOnboardingSubmissions, ReferralTrafficSubmissions, TrafficVSLSubmissions, WebPresenceSubmissions, WebsiteVSLSubmissions }