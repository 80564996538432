import React, {useState} from "react";
// react plugin for creating charts
//import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
//import Store from "@material-ui/icons/Store";
// import Warning from "@material-ui/icons/Warning";
//import DateRange from "@material-ui/icons/DateRange";
//import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
//import ArrowUpward from "@material-ui/icons/ArrowUpward";
//import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import ShopIcon from "@material-ui/icons/Shop";
import ShopTwoIcon from "@material-ui/icons/ShopTwo";
//import Code from "@material-ui/icons/Code";
//import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
//import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
// import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Editable from "components/Table/Editable.js";
import { auth } from 'firebase.js';

//import { bugs, website, server } from "variables/general.js";

//import { TogglWeekly } from "variables/toggl.js";
//import { TwilioCallLogs } from "variables/twilio.js";
// import { FBCampaigns, FBCampaignAdSpend } from "variables/facebook.js"
//import {
//  dailySalesChart,
//  emailsSubscriptionChart,
//  completedTasksChart,
//} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function PricingPage() {
  const [InitialBuildQuantity,setInitialBuildQuantity] = useState(1);
  const [LandingPagesQuantity,setLandingPagesQuantity] = useState(0);
  const [PageModificationHours,setPageModificationHours] = useState(0);
  const [MeetingsHours,setMeetingsHours] = useState(0);
  const [ContentUpdateHours,setContentUpdateHours] = useState(0);
  const [SupportHours,setSupportHours] = useState(0);
  const [HostingYears,setHostingYears] = useState(0);

  let WebsiteCosts = [];
  WebsiteCosts[0] = ["Home Page & Initial Build", 1000];
  WebsiteCosts[1] = ["Additional Landing Pages", 500];
  WebsiteCosts[2] = ["Page Structure Modifications", 150];
  WebsiteCosts[3] = ["Meetings (in Person or Virtual)", 100];
  WebsiteCosts[4] = ["Content Updates & Refreshes", 100];
  WebsiteCosts[5] = ["Support (email or phone)", 100];
  let Hosting = [];
  Hosting[0] = ["Webflow", "Tier 1", "300"];
  Hosting[1] = ["Webflow", "Tier 2", "390"];
  Hosting[2] = ["Webflow", "Tier 3", "900"];
  let ContentCreation = [];
  ContentCreation[0] = ["Photography", 150];
  ContentCreation[1] = ["Photo Editing", 80];
  ContentCreation[2] = ["Videography", 200];
  ContentCreation[3] = ["Video Editing", 80];

  const classes = useStyles();
  const loginState = auth.currentUser;
  if (loginState != undefined) {
  return (
    <div>
      <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <h3 className={classes.cardTitle}>Website Calculator</h3>
            </CardHeader>
            <CardBody>
              <label>Change to step by step walkthrough</label>
              <br />
              <div><label>{WebsiteCosts[0][0]}</label>
                <Editable
                  text={InitialBuildQuantity}
                  placeholder="1"
                  type="input"
                >
                  <input
                  type="text"
                  name="hours"
                  placeholder="1"
                  value={InitialBuildQuantity}
                  onChange={e => setInitialBuildQuantity(e.target.value)}
                  />
                  </Editable>
                </div>
                <div><label>{WebsiteCosts[1][0]}</label>
                <Editable
                  text={LandingPagesQuantity}
                  placeholder="0"
                  type="input"
                >
                  <input
                  type="text"
                  name="hours"
                  placeholder="0"
                  value={LandingPagesQuantity}
                  onChange={e => setLandingPagesQuantity(e.target.value)}
                  />
                  </Editable>
                </div>
                <div><label>Additional Hours: {WebsiteCosts[2][0]}</label>
                <Editable
                  text={PageModificationHours}
                  placeholder="0"
                  type="input"
                >
                  <input
                  type="text"
                  name="hours"
                  placeholder="0"
                  value={PageModificationHours}
                  onChange={e => setPageModificationHours(e.target.value)}
                  />
                  </Editable>
                </div>
                <div><label>Additional Hours: {WebsiteCosts[3][0]}</label>
                <Editable
                  text={MeetingsHours}
                  placeholder="0"
                  type="input"
                >
                  <input
                  type="text"
                  name="hours"
                  placeholder="0"
                  value={MeetingsHours}
                  onChange={e => setMeetingsHours(e.target.value)}
                  />
                  </Editable>
                </div>
                <div><label>Additional Hours: {WebsiteCosts[4][0]}</label>
                <Editable
                  text={ContentUpdateHours}
                  placeholder="0"
                  type="input"
                >
                  <input
                  type="text"
                  name="hours"
                  placeholder="0"
                  value={ContentUpdateHours}
                  onChange={e => setContentUpdateHours(e.target.value)}
                  />
                  </Editable>
                </div>
                <div><label>Additional Hours: {WebsiteCosts[5][0]}</label>
                <Editable
                  text={SupportHours}
                  placeholder="0"
                  type="input"
                >
                  <input
                  type="text"
                  name="hours"
                  placeholder="0"
                  value={SupportHours}
                  onChange={e => setSupportHours(e.target.value)}
                  />
                  </Editable>
                </div>
              <Table
              tableHeaderColor="warning"
              tableHead={["Component", "Fee", "Quantity", "Price"]}
              tableData={[
                [WebsiteCosts[0][0], "$"+WebsiteCosts[0][1], InitialBuildQuantity, "$"+(WebsiteCosts[0][1]*InitialBuildQuantity)],
                [WebsiteCosts[1][0], "$"+WebsiteCosts[1][1], LandingPagesQuantity, "$"+(WebsiteCosts[1][1]*LandingPagesQuantity)],
                ["Subtotal: ","","", "$"+(WebsiteCosts[0][1]*InitialBuildQuantity+WebsiteCosts[1][1]*LandingPagesQuantity)],
              ]}
            />
            <Table
              tableHeaderColor="warning"
              tableHead={["Additional Services", "Hourly Rate", "Hours", "Price"]}
              tableData={[
                [WebsiteCosts[2][0], "$"+WebsiteCosts[2][1], PageModificationHours, "$"+(WebsiteCosts[0][1]*PageModificationHours)],
                [WebsiteCosts[3][0], "$"+WebsiteCosts[3][1], MeetingsHours, "$"+(WebsiteCosts[1][1]*MeetingsHours)],
                [WebsiteCosts[4][0], "$"+WebsiteCosts[4][1], ContentUpdateHours, "$"+(WebsiteCosts[0][1]*ContentUpdateHours)],
                [WebsiteCosts[5][0], "$"+WebsiteCosts[5][1], SupportHours, "$"+(WebsiteCosts[1][1]*SupportHours)],
                ["Subtotal: ","","","$"+(WebsiteCosts[0][1]*PageModificationHours+WebsiteCosts[1][1]*MeetingsHours+WebsiteCosts[0][1]*ContentUpdateHours+WebsiteCosts[1][1]*SupportHours)],
              ]}
            />
            <Table
              tableHeaderColor="warning"
              tableHead={["Hosting Tier", "Annual Rate", "Years", "Price"]}
              tableData={[
                [Hosting[0][1], "$"+Hosting[0][2], HostingYears, "$"+Hosting[0][2]*HostingYears*0],
                [Hosting[1][1], "$"+Hosting[1][2], HostingYears, "$"+Hosting[1][2]*HostingYears*0],
                [Hosting[2][1], "$"+Hosting[2][2], HostingYears, "$"+Hosting[1][2]*HostingYears*0],
              ]}
            />
            <p>Total: ${(WebsiteCosts[0][1]*InitialBuildQuantity+WebsiteCosts[1][1]*LandingPagesQuantity) + (WebsiteCosts[0][1]*PageModificationHours+WebsiteCosts[1][1]*MeetingsHours+WebsiteCosts[0][1]*ContentUpdateHours+WebsiteCosts[1][1]*SupportHours)}</p>
            </CardBody>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <h3 className={classes.cardTitle}>Web Hosting Tiers Explained</h3>
              <p className={classes.cardCategory}>Hosting</p>
            </CardHeader>
            <CardBody>
              <ul>
                <li>Webflow: Tier 1 [Webflow Basic]</li>
                <ul>
                  <li>50GB bandwidth / month</li>
                  <li>25,000 monthly visits /month</li>
                  <li>100 form submissions/month</li>
                </ul>
              <li>Webflow: Tier 2 [Webflow CMS]</li>
              <ul>
                <li>200GB bandwidth / month</li>
                <li>100,000 monthly visits /month</li>
                <li>1,000 form submissions /month</li>
                <li>2 editors	- Means they can edit and update content on the site themselves</li> 
              </ul>
              <li>Webflow: Tier 3 [Webflow Business]</li>
              <ul>
                <li>400GB bandwidth / month</li>
                <li>500,000 monthly visits</li>
                <li>2,000 form submissions</li>
                <li>8 content editors</li>
              </ul>
            </ul>
            </CardBody>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <h3 className={classes.cardTitle}>Ecommerce Hosting Tiers Explained</h3>
              <p className={classes.cardCategory}>Hosting</p>
            </CardHeader>
            <CardBody>
            <CustomTabs
            title="Platform:"
            headerColor="primary"
            tabs={[
              {
                tabName: "Webflow",
                tabIcon: ShopIcon,
                tabContent: (
                  <ul>
                  <li>Webflow: Tier 1 [Webflow Standard]</li>
                  <ul>
                    <li>500 Ecommerce Products</li>
                    <li>200GB bandwidth / month</li>
                    <li>100,000 monthly visits /month</li>
                    <li>1,000 form submissions /month</li>
                    <li>2 editors	- Means they can edit and update content on the site themselves</li> 
                    <li>2% Transaction Fee</li>
                    <li>3 Staff Accounts</li>
                    <li>Max $50k USD Yearly Sales Revenue</li> 
                  </ul>
                <li>Webflow: Tier 2 [Webflow Plus]</li>
                <ul>
                  <li>1000 Ecommerce Products</li>
                  <li>400GB bandwidth / month</li>
                  <li>500,000 monthly visits</li>
                  <li>2,000 form submissions</li>
                  <li>8 content editors</li>
                  <li>Unbranded Emails</li> 
                  <li>0% Transaction Fee</li>
                  <li>10 Staff Accounts</li>
                  <li>Max $200k USD Yearly Sales Revenue</li> 
                </ul>
                <li>Webflow: Tier 3 [Webflow Advanced]</li>
                <ul>
                  <li>3000 Ecommerce Products</li>
                  <li>400GB bandwidth / month</li>
                  <li>500,000 monthly visits</li>
                  <li>2,000 form submissions</li>
                  <li>8 content editors</li>
                  <li>Unbranded Emails</li>
                  <li>0% Transaction Fee</li>
                  <li>15 Staff Accounts</li>
                  <li>Unlimited Yearly Sales Revenue</li> 
                </ul>
                </ul>
                ),
              },
              {
                tabName: "Shopify",
                tabIcon: ShopTwoIcon,
                tabContent: (
<ul>
              <li>Basic Shopify</li>
              <ul>
                <li>Unlimited Products</li>
                <li>2 Staff Accounts</li>
                <li>4 Inventory Locations</li>
                <li>No Baked-In Reports</li>
                <li>Online Australian credit card rates: 1.75% + 30¢ AUD</li>
                <li>Online International/Amex credit card rates: 2.9% + 30¢ AUD</li>
                <li>Bancontact rates: 2.9% + 30¢ AUD</li>
                <li>iDEAL rates: 2.9% + 30¢ AUD</li>
                <li>In-person credit/debit card rates: 1.9% + 0¢ AUD</li>
                <li>Transaction fee if not using Shopify Payments: 2.0%</li>
                <li>No International Domains</li>
                <li>No International Pricing</li>
              </ul>
              <li>Normal Shopify</li>
              <ul>
                <li>Unlimited Products</li>
                <li>5 Staff Accounts</li>
                <li>5 Inventory Locations</li>
                <li>Standard Baked-In Reports</li>
                <li>Online Australian credit card rates 1.6% + 30¢ AUD</li>
                <li>Online International/Amex credit card rates 2.8% + 30¢ AUD</li>
                <li>Bancontact rates: 2.8% + 30¢ AUD</li>
                <li>iDEAL rates: 2.8% + 30¢ AUD</li>
                <li>In-person credit/debit card rates: 1.7% + 0¢ AUD</li>
                <li>Transaction fee if not using Shopify Payments: 1.0%</li>
                <li>International Domains</li>
                <li>No International Pricing</li>
              </ul>
              <li>Advanced Shopify</li>
              <ul>
                <li>Unlimited Products</li>
                <li>15 Staff Accounts</li>
                <li>8 Inventory Locations</li>
                <li>Advanced Baked-In Reports</li>
                <li>Online Australian credit card rates 1.4% + 30¢ AUD</li>
                <li>Online International/Amex credit card rates 2.7% + 30¢ AUD</li>
                <li>Bancontact rates: 2.7% + 30¢ AUD</li>
                <li>iDEAL rates: 2.7% + 30¢ AUD</li>
                <li>In-person credit/debit card rates: 1.5% + 0¢ AUD</li>
                <li>Transaction fee if not using Shopify Payments: 0.5%</li>
                <li>International Domains</li>
                <li>International Pricing</li>
              </ul>
            </ul>
                ),
              },
            ]}
          />
            </CardBody>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>content_copy</Icon>
              </CardIcon>
              <h3 className={classes.cardTitle}>Content Creation</h3>
              <div className={classes.cardCategory}></div>
            </CardHeader>
            <CardBody>
            <Table
                tableHeaderColor="warning"
                tableHead={["Service", "Hourly Rate", "Hours", "Price"]}
                tableData={[
                  ["Photography", "150", "1", "150"],
                  ["Photo Editing", "80", "1", "80"],
                  ["Videography", "200", "1", "200"],
                  ["Video Editing", "80", "1", "80"],
                ]}
              />
            </CardBody>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>content_copy</Icon>
              </CardIcon>
              <h3 className={classes.cardTitle}>SEO Calculator</h3>
              <div className={classes.cardCategory}>Data Go Here</div>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>content_copy</Icon>
              </CardIcon>
              <h3 className={classes.cardTitle}>Ecommerce Calculator</h3>
              <p className={classes.cardCategory}></p>
            </CardHeader>
            <CardBody>
            <Table
                tableHeaderColor="warning"
                tableHead={["Component", "Fee", "Quantity", "Price"]}
                tableData={[
                  ["Core Pages", "2000", "Yes", "2000"],
                  ["Blog & CMS Functionality", "600", "Yes", "80"],
                  ["Additional Landing Pages /Focus Pages", "500", "0", "0"],
                  ["Additional Technical Pages", "150", "0", "0"],
                  ["Subtotal:","","","0"],
                ]}
              />
              <label>Core Pages Include: Home Page; Collection Page w/ Filters; Product Page; Checkout Experience; About; Privacy Policy; Shipping Information; FAQs</label>
              <Table
                tableHeaderColor="warning"
                tableHead={["Additional Services", "Hourly Rate", "Hours", "Price"]}
                tableData={[
                  ["Page structure modifications", "150", "0", "0"],
                  ["Meetings (in person or virtual)", "100", "0", "0"],
                  ["Content updates & refreshes", "100", "0", "0"],
                  ["Support (email or phone)", "100", "0", "0"],
                ]}
              />
              <Table
                tableHeaderColor="warning"
                tableHead={["Compulsory App Costs", "Monthly Rate direct to customer (USD)", "Months", "Price"]}
                tableData={[
                  ["Fancy & Simple", "68", "0", "0"],
                  ["Fancy & Professional", "223", "0", "0"],
                  ["Fancy & Powerful", "496", "0", "0"],
                  ["Straight Forward & Basic", "92", "0", "0"],
                  ["Straight Forward & Professional", "222", "0", "0"],
                  ["Straight Forward & Powerful", "652", "0", "0"],
                  ["Shopify POS Add On", "89", "0", "0"],
                ]}
              />
            </CardBody>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>content_copy</Icon>
              </CardIcon>
              <h3 className={classes.cardTitle}>Ecommerce Breakdowns</h3>
              <p className={classes.cardCategory}></p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="warning"
                tableHead={["Fancy & Simple - Heavily stylised but with limited functionality", "Cost"]}
                tableData={[
                  ["Webflow. Less than 500 products", 29],
                  ["Yotpo. Reviews", 30],
                  ["Nudgify. Product pushes", 9],
                ]}
              />
              <Table
                tableHeaderColor="warning"
                tableHead={["Fancy & Professional - Heavily stylised with simple functionality", "Cost"]}
                tableData={[
                  ["Webflow. Less than 1000 products", 74],
                  ["Yotpo. Reviews & UGC", 120],
                  ["Nudgify. Product pushes that are more pro", 29],
                ]}
              />
              <Table
                tableHeaderColor="warning"
                tableHead={["Fancy & Powerful", "Cost"]}
                tableData={[
                  ["Webflow. Less than 3000 products", 212],
                  ["Yotpo. Reviews & UGC in higher volume", 165],
                  ["Foxy. Advanced store functionality (does have lower cost if willing to take a higher transaction fee)", 30],
                  ["Nudgify. Product pushes at higher volume.", 89],
                ]}
              />
              <Table
                tableHeaderColor="warning"
                tableHead={["Straight Forward & Basic - Classic designs with simple functionality", "Cost"]}
                tableData={[
                  ["Shopify. Limited Shopify reporting and only 2 staff accounts", 29],
                  ["Shogun. Page Builder", 39],
                  ["Judge.me. Reviews", 15],
                  ["Nudgify. Product pushes", 9],
                ]}
              />
              <Table
                tableHeaderColor="warning"
                tableHead={["Straight Forward & Professional - Classic designs with robust fuctionality", "Cost"]}
                tableData={[
                  ["Shopify. Standard reporting and 10 staff accounts", 79],
                  ["Shogun. Page builder with more views allowed", 99],
                  ["Judge.me . Reviews", 15],
                  ["Nudgify. Product pushes that are more pro", 29],
                ]}
              />
              <Table
                tableHeaderColor="warning"
                tableHead={["Straight Forward & Powerful - Classic designs with high volume and complex functionality", "Cost"]}
                tableData={[
                  ["Shopify. Complex everything", 299],
                  ["Shogun. Page builder with more views allowed", 99],
                  ["Yotpo. Reviews and UGC w/ high volume", 165],
                  ["Nudigy. Professional with heaps of volume", 89],
                ]}
              />
            </CardBody>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );} else {
    return (
      <div> 403 - Unauthorised Access</div>
    )
  }
}
