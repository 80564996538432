// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import PricingPage from "views/Calculators/Pricing.js";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/calc",
    name: "Calculator",
    icon: Dashboard,
    component: PricingPage,
    layout: "/admin",
  },
];

export default dashboardRoutes ;
