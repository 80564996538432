import React from "react";
import axios from "axios";

import ChartistGraph from "react-chartist";
import Chartist from "chartist";

import ShopIcon from "@material-ui/icons/Shop";
import CustomTabs from "components/CustomTabs/CustomTabs.js";

var togglUid = process.env.REACT_APP_TOGGL_UID;
var authPW = "api_token";

class TogglChart extends React.Component {
  state = {
    weeklyData: [],
    clientHours: [],
    projectHours: [],
}

componentDidMount() {
  let clientNames = [];
  let clientHours = [];
  let projectHours = [];
  let clientName = "";
  let projectName = "";

  axios( {
    auth: {
        username: togglUid,
        password: authPW
    },
    method: "get",
    url: "https://api.track.toggl.com/reports/api/v2/weekly?user_agent=chris@whereu.com.au&workspace_id=3692453"
    })
    .then(res => {
        const weeklyData = res.data.data;
        this.setState({ weeklyData });
        for (var i = 0; i < weeklyData.length; i++) { // Retrieve Client List & Init Client Hours
          clientName = weeklyData[i].title.client;
          clientNames[clientName] = clientName;
          clientHours[clientName] = [];
          clientHours[clientName]["Total"] = 0;
        }
        for (var i = 0; i < weeklyData.length; i++) { // Tally Project Hours for Week
            for (var e = 0; e < weeklyData[i].totals.length; e++){
              clientName = weeklyData[i].title.client;
              projectName = weeklyData[i].title.project;
              if (weeklyData[i].totals[e] == null){ // replace null values for math ops
                weeklyData[i].totals[e] = 0;
              }
              if (projectHours[projectName] == null){ // replace null values for math ops
                projectHours[projectName] = 0;
              }
              projectHours[projectName] = projectHours[projectName] + weeklyData[i].totals[7];
              clientHours[clientName][projectName] = weeklyData[i].totals[7];
              clientHours[clientName]["Total"] = clientHours[clientName]["Total"] + weeklyData[i].totals[7];
          }
        }
        this.setState({ clientHours });
        this.setState({ projectHours });

    })
}

msToHms(d) {
  d = Number(d);
  var h = Math.floor(d / 3600000);
  var m = Math.floor(d % 3600000 / 60000);
  var s = Math.floor(d % 3600000 % 60000 / 1000);

  return (h + ":" + m + ":" + s); 
}

projectChartConstructor(toggleHours){
  let names = Object.keys(toggleHours)
  let values = Object.keys(toggleHours).map(projects =>
    {    
      return toggleHours[projects]
})
for (let x = 0; x < names.length; x++){
  let hours = Math.floor(values[x] / 3600000);
  if (hours == 0){
    names.splice(x, 1)
    values.splice(x,1)
  }
}
  let data = {
    labels: names,
    series: values
  }
return data
}

clientChartConstructor(toggleHours){
  let names = Object.keys(toggleHours)
  let values = Object.keys(toggleHours).map(names =>
    {    
      return toggleHours[names]["Total"]
})
for (let x = 0; x < names.length; x++){
  let hours = Math.floor(values[x] / 3600000);
  if (hours == 0){
    names.splice(x, 1)
    values.splice(x,1)
  }
}
  let data = {
    labels: names,
    series: values
  }
return data
}

render() {
  let options = {
    width: "500px",
    height: "500px",
    donut: false,
    labelOffset: 100,
    chartPadding: 100
  };
  let type = "Pie"
  return (
    <>
    <CustomTabs
            title="Hours Spent:"
            headerColor="primary"
            tabs={[
              {
                tabName: "By Client",
                tabIcon: ShopIcon,
                tabContent: (
                  <ChartistGraph
                  data={this.clientChartConstructor(this.state.clientHours)}
                  type={type}
                  options={options}
                />
                )
              },
              {
                tabName: "By Task",
                tabIcon: ShopIcon,
                tabContent: (
                  <ChartistGraph
                  data={this.projectChartConstructor(this.state.projectHours)}
                  type={type}
                  options={options}
                />
                )
              },
          ]}
          />
  </>
  )
}
}

export { TogglChart }