import React from 'react'
import axios from "axios"

let today = new Date();
let startTime = new Date(new Date().setDate(today.getDate() - 31));
startTime = Math.round(startTime.getTime() / 1000);

//https://api.stripe.com/v1/payouts

class StripeSummary extends React.Component {
    state = {
      StripeInvoices: [],
      StripeInvoiceData: [],
  }

  renderTable(InvoiceData){
    return InvoiceData.map((value, index) => {
    return(
        <tr key={InvoiceData[index]["invoiceId"]}>
            <td>{InvoiceData[index]["customerName"]}</td>
            <td>{this.convertEpoch((InvoiceData[index]["dueDate"]*1000))}</td>
            <td>{InvoiceData[index]["paymentStatus"]}</td>
            <td>${(InvoiceData[index]["total"]/100)}</td>
            <td>{InvoiceData[index]["customerEmail"]}</td>
        </tr>
    )
    })}

convertEpoch(time){
  return new Date(time).toLocaleDateString('en-GB')
}
  componentDidMount() {
    axios( {
      headers: {
        Authorization: "Bearer " + process.env.REACT_APP_STRIPE_SECRET,
      },
      method: "get",
      url: "https://api.stripe.com/v1/invoices?created[gte]="+startTime+"&limit=100",
    })
        .then(res => {
          const StripeInvoiceData = res.data.data;
          let StripeInvoices = []
          if (StripeInvoiceData[0] === undefined || StripeInvoiceData[0]["id"] === undefined){
            StripeInvoices[0] = {
              "invoiceId": "NA", 
              "dueDate": "NA", 
              "paymentStatus": "NA",
              "total": "NA",
              "customerEmail": "NA",
              "customerName": "NA",
          } } else {
          for (let i = 0; i < StripeInvoiceData.length; i++){
            StripeInvoices[i] = {
                "invoiceId": StripeInvoiceData[i]["id"], 
                "dueDate": StripeInvoiceData[i]["due_date"], 
                "paymentStatus": StripeInvoiceData[i]["status"],
                "total": StripeInvoiceData[i]["total"],
                "customerEmail": StripeInvoiceData[i]["customer_email"],
                "customerName": StripeInvoiceData[i]["customer_name"],
            }
          }}

          this.setState({ StripeInvoices });
          this.setState({ StripeInvoiceData });
      })
      .catch((error) => {
        console.log(error); //Logs a string: Error: Request failed with status code 404
      });
      }
  
      render() {
        let tableRow
        let logData
        if (this.state.StripeInvoices === undefined || this.state.StripeInvoices[0] === undefined || this.state.StripeInvoices[0]["invoiceId"] === undefined){tableRow = <p>Data is Loading. . .</p>}
        else {
          tableRow = this.renderTable(this.state.StripeInvoices)
        }
        return (
          <> 
          <table>
            <tr>
              <th>Name</th>
              <th>Date</th>
              <th>Status</th>
              <th>Total</th>
              <th>Email</th>
            </tr>
            {tableRow}
          </table>
          </>
        )
      }
    }

    class StripeMRR extends React.Component {
      state = {
        totalValue: []
    }

    componentDidMount() {
      axios( {
        headers: {
          Authorization: "Bearer " + process.env.REACT_APP_STRIPE_SECRET,
        },
        method: "get",
        url: "https://api.stripe.com/v1/invoices?created[gte]="+startTime+"&limit=100",
      })
          .then(res => {
            let totalValue = 0;
            const StripeInvoiceData = res.data.data;
            if (StripeInvoiceData[0] === undefined || StripeInvoiceData[0]["id"] === undefined){
              totalValue = 0;
              } else {
            for (let i = 0; i < StripeInvoiceData.length; i++){
                  totalValue = totalValue + StripeInvoiceData[i]["total"]
                }
              }
            this.setState({ totalValue });
        })
        .catch((error) => {
          console.log(error); //Logs a string: Error: Request failed with status code 404
        });
        }
    
        render() {
          let mrr
          if (this.state.totalValue === undefined ){mrr = ". . ."}
          else {
            mrr = (this.state.totalValue / 100).toLocaleString();
          }
          return (
            <> 
              ${mrr}
            </>
          )
        }
      }

export { StripeSummary, StripeMRR }